<template>
    <Guest>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold uppercase text-white">
                Inloggen
            </h2>
            <h2 class="mt-2 text-center text-3xl leading-9 font-extrabold uppercase text-white">
                Clubomgeving
            </h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form v-on:submit.prevent="login">
                    <TextInput v-model:value="form.email.value" v-model:errors="form.email.errors" label="E-mailadres" placeholder="E-mailadres" type="text" border="enable" autofocus />
                    <TextInput v-model:value="form.password.value" v-model:errors="form.password.errors" label="Wachtwoord" placeholder="Wachtwoord" type="password" border="enable" class="mt-4" />
                    <div class="mt-6 flex items-center justify-between">
                        <div class="flex items-center">
                            <input id="remember_me" type="checkbox" v-model="form.remember_me" class="transition duration-150 ease-in-out">
                            <label for="remember_me" class="ml-2 block text-xs sm:text-sm text-gray-800">
                                Onthoud mij
                            </label>
                        </div>
                        <div class="text-sm">
                            <router-link :to="{ name: 'password.forgot'}" class="link text-xs sm:text-sm">
                                Wachtwoord vergeten?
                            </router-link>
                        </div>
                    </div>
                    <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-3">        
                        <button type="submit" class="button w-full">
                            Inloggen
                        </button>
                        <router-link :to="{ name: '' }">    
                            <button type="button" class="button-black w-full">
                                Account aanvragen
                            </button>
                        </router-link>
                    </div>
                </form>
            </div>
        </div>
    </Guest>
</template>

<script>
import Guest from "./wrappers/Guest";
import TextInput from "@/components/forms/TextInput";

export default {
    name: "Login",
    components:{
        Guest,
        TextInput,
    },
   
    data() {
        return {
            form: {
                email: {
                    value: '',
                    errors: []
                },
                password: {
                    value: '',
                    errors: []
                },
                remember_me: false,
            },
            error_message: "",
        }
    }, 
}
</script>